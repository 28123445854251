.nav {
    padding: 10px;
    margin: 10px;
    list-style-type: none;

    width: 180px;
    height: calc(100vh-220px);
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 100;

    /* border-right: 1px solid goldenrod;
    border-bottom: 1px solid goldenrod; */
    /* background-color: rgba(108, 117, 125, 0.666); */

}

.links {
    line-height: 40px;
    display: block;
    width: 160px;
    cursor: pointer;
    padding: 0 10px;
    border-radius: 10px;
    margin-top: 10px;
    color: black;
    text-decoration: none;
    font-size: 16px;
    /* border-bottom: 2px solid goldenrod; */
}

.sublink {
    display: none;
}

.links:hover {
    background-color: rgb(19, 19, 77);
    text-decoration: none;
    color: white;
    border: solid 1px goldenrod;
}

.links:hover .sublink {
    display: block;
    position: relative;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 20px;
    padding: 10px;
    cursor: pointer;

}


.sublink:hover {
    display: block;
    background-color: rgb(19, 19, 77);
    color: white;


}

.link {
    text-decoration: none;
    color: white;
    font-size: 24px;
}

.submenu {
    display: flex;
    flex-direction: column;
    left: 20px;
    color: white;
}

.submenu-link {
    display: block;
    color: white;
    text-decoration: none;
    padding-left: 30px;
}

.submenu-link:hover {
    background-color: #708090;
    color: white;
    text-decoration: none;
    border-radius: 10px;
    padding-bottom: 5px;
}

.linkname {
    margin-left: 10px;

}

.sidebarTitle {
    color: grey;
    padding-top: 10px;
    margin-top: 5px;
    font-size: 16px;
}

@media screen and (max-width: 799px) {

    .links {
        line-height: 40px;
        display: block;
        width: 160px;
        cursor: pointer;
        padding: 0 10px;
        border-radius: 10px;
        margin-top: 10px;
        color: black;
        text-decoration: none;
        font-size: 16px;
    }

}