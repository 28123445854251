.warning {
    color: rgb(61, 58, 58);
    font-size: 20px;
    word-spacing: 1px;
    text-size-adjust: 1px;

}

.login {
    height: 100vh;
    background-color: ghostwhite;
    display: flex;
    justify-content: center;
    align-items: center;



}

.login-title {
    font-size: 24px;
    color: black;

}

.highlighted {
    color: goldenrod;
    font-size: 28px;
}

.loginError {
    background-color: red;
}

.loginlabel {
    font-size: 18px;
}

.formitself {
    box-shadow: 10px 10px 5px rgb(87, 93, 95);
    padding: 15px;
    width: 360px;
    height: 300px;
}

.table-card {
    border: 1px solid goldenrod;
    background-color: bisque;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.table-logo {}

.table-data-label {
    font-size: 16px;
}