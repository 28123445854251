.welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;



    height: 100vh;
}

.img {
    image-resolution: inherit;
    image-rendering: optimizeQuality;

}