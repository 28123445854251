.contribution-card {
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    opacity: 1
}

.summary {
    border: solid 1px green;
    width: 500px;
    height: 300px;

    /* background-image: url(`../../../../assets/img/banknote.jpeg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;






}

.narration {
    color: black;
    font-weight: bolder;
    margin-left: 16px;
    margin-top: 20px;
    z-index: 100;
    opacity: 1;
}

.amount {
    color: rgb(24, 23, 23);
    margin: auto;
    font-size: 30px;

}