.group-header {
    background-color: rgb(182, 208, 233);
    line-height: 80px;
    display: block;
    width: 100%;


}

.card {
    border-radius: 2px solid gold;
    border-radius: 10px;
}

.data {
    color: red
}

.money {
    color: darkgreen;
    font-size: larger;
    font-weight: bolder;
}

.balance-display {
    color: gold;
    width: 100vw;
    width: 100%;
    height: 100px;
    background-color: rgb(23, 92, 23);
    border-radius: 20px;
    padding: 5px;
    margin: 0 10px;
}

.value {
    font-size: 40px;
    font-weight: bolder;
}

.value-red {
    font-size: 40px;
    color: red;
    font-weight: bolder;
}

.loan-display {
    color: black;
    width: 100vw;
    width: 100%;
    height: 100px;
    background-color: rgb(169, 172, 169);
    border-radius: 20px;
    padding: 5px;
    margin: 0 10px;
}