.main {


    padding-top: 50px;
    position: relative;
    /* background-color: aqua; */
    height: 100vh;
}

main::before {
    content: '';
    background-image: url(../../assets/img/nairobi.jpeg);
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    opacity: .1;
    z-index: -100;

}


.content-div {
    width: calc(100vw - 200px);
    margin-left: 200px;
    padding-left: 50px;

}

@media screen and (max-width: 799px) {
    .content-div {
        width: calc(100vw - 30px);
        margin-left: 40px;
        padding-left: 40px;

    }
}