.card {
    width: 400px;
    border: 2px solid gold;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 5px 5px;
}

.card-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    padding: 5px;
    margin-bottom: 10px;

}

.card-label {
    font-size: 16px;
}

.card-data {
    font-size: 16px;
    font-weight: bold;
}

.loan {
    color: rgb(140, 10, 4);
}

.contribution {
    color: green;
    font-size: larger;
    font-weight: bolder;
}