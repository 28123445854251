.details {
    width: 90%;
}

.dash-col {
    border: solid 1px goldenrod;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.dash-label {
    color: darkgray;
    font-size: 16px;
}

.dash-stat {
    color: green;
    font-size: 36px;
    font-weight: 700;
}

.dash-h1 {
    color: black;
    text-transform: lowercase;
}

.dash-h1-highlight {
    color: goldenrod;
    text-transform: uppercase;
    font-size: larger;
    font-weight: bolder;
}